define("discourse/plugins/discourse-ai/discourse/components/rag-upload-progress", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _didInsert, _service, _dIcon, _decorators, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _RagUploadProgress;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let RagUploadProgress = _exports.default = (_class = (_RagUploadProgress = class RagUploadProgress extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "messageBus", _descriptor, this);
      _initializerDefineProperty(this, "updatedProgress", _descriptor2, this);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe(`/discourse-ai/ai-persona-rag/${this.args.upload.id}`);
    }
    trackProgress() {
      this.messageBus.subscribe(`/discourse-ai/ai-persona-rag/${this.args.upload.id}`, this.onIndexingUpdate);
    }
    onIndexingUpdate(data1) {
      // Order not guaranteed. Discard old updates.
      if (!this.updatedProgress || this.updatedProgress.left > data1.left) {
        this.updatedProgress = data1;
      }
    }
    get calculateProgress() {
      return Math.ceil(this.progress.indexed * 100 / this.progress.total);
    }
    get fullyIndexed() {
      return this.progress && this.progress.left === 0;
    }
    get progress() {
      if (this.updatedProgress) {
        return this.updatedProgress;
      } else if (this.args.ragIndexingStatuses) {
        return this.args.ragIndexingStatuses[this.args.upload.id];
      } else {
        return [];
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <td
        class="persona-rag-uploader__upload-status"
        {{didInsert this.trackProgress}}
      >
        {{#if this.progress}}
          {{#if this.fullyIndexed}}
            <span class="indexed">
              {{icon "check"}}
              {{I18n.t "discourse_ai.ai_persona.uploads.indexed"}}
            </span>
          {{else}}
            <span class="indexing">
              {{icon "robot"}}
              {{I18n.t "discourse_ai.ai_persona.uploads.indexing"}}
              {{this.calculateProgress}}%
            </span>
          {{/if}}
        {{else}}
          <span class="uploaded">{{I18n.t
              "discourse_ai.ai_persona.uploads.uploaded"
            }}</span>
        {{/if}}
      </td>
    
  */
  {
    "id": "otL3+nu2",
    "block": "[[[1,\"\\n    \"],[11,\"td\"],[24,0,\"persona-rag-uploader__upload-status\"],[4,[32,0],[[30,0,[\"trackProgress\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"progress\"]],[[[41,[30,0,[\"fullyIndexed\"]],[[[1,\"          \"],[10,1],[14,0,\"indexed\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"check\"],null]],[1,\"\\n            \"],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.indexed\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"indexing\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"robot\"],null]],[1,\"\\n            \"],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.indexing\"],null]],[1,\"\\n            \"],[1,[30,0,[\"calculateProgress\"]]],[1,\"%\\n          \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[10,1],[14,0,\"uploaded\"],[12],[1,[28,[32,2,[\"t\"]],[\"discourse_ai.ai_persona.uploads.uploaded\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/rag-upload-progress.js",
    "scope": () => [_didInsert.default, _dIcon.default, _discourseI18n.default],
    "isStrictMode": true
  }), _RagUploadProgress), _RagUploadProgress), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updatedProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "trackProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "trackProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onIndexingUpdate", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onIndexingUpdate"), _class.prototype)), _class);
});